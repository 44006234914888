import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { Box, 
    Heading,
    Link,
    Flex
} from "@chakra-ui/react"

import BlogPost from "./BlogPost"


const BlogPostsBlock = ( props ) => {

    return (
         <>
            <Box backgroundColor="rgba(255, 255, 255, 0.8)" padding={{sm: '2', md: '8', lg: '4'}}>
              <Box>
              <Link as={GatsbyLink} to="/news">
                  <Heading as="h1" color="red.400" textAlign="center" marginBottom="4" fontSize={{sm: '1.5rem', md: '2rem'}}>
                  The Latest News
                  </Heading>
              </Link>
             </Box>
            <Flex>

            </Flex>
            <BlogPost />

             </Box>
          </>
    )
}

export default BlogPostsBlock
import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Box, 
  Container,
  Heading,
  Text } from "@chakra-ui/react"

import Seo from "../components/seo"
import HomeBackground from "../components/HomePage/HomeBackground"
import SidebarNav from "../components/SidebarNav/SidebarNav"
import PageHero from "../components/PageHero/PageHero"
import MainContentBox from "../components/MainContentBox/MainContentBox"
import MobileNav from "../components/MobileNav/MobileNav"
import Footer from "../components/Footer/Footer"
import ProjectCardRack from "../components/ProjectCard/ProjectCardRack"
import SliceZone from "../components/SliceZone/SliceZone"
import BlogPostsBlock from "../components/BlogPostsBlock/BlogPostsBlock"
import NewsletterBlock from "../components/NewsletterSignupBlock/NewsletterSignupBlock"
//import FacebookFeed from "../components/FacebookFeed/FacebookFeed"
import { RichText } from "prismic-reactjs"
import { CustomLink } from "../utils/customLink"

const IndexPage = ({ location, data }) => {

  const pageLocation = location.pathname
  if (!data) return null
  const document = data.allPrismicHomepage.edges[0].node.data

  const heroContent = {
    title: document.page_title.text,
    description: document.page_description.text,
    ctaButtons: document.cta_buttons
  }

return (
  <>
  <Seo title="Valley Advocates For Responsible Development" />
      <HomeBackground>
        {/* <Box  display={{ sm: 'none', md: 'block' }} >
          <Header {...props} /
        </Box> */}
        <Container maxW={{lg: '100%', xl: 'container.xl'}}>
          {/* <StickyHeader /> */}
          <MobileNav />
          <Box paddingTop="20" width="100%">
          
              <SidebarNav 
                pathName={pageLocation}
              />
        
          <MainContentBox>
           <PageHero 
              title={heroContent.title}
              description={heroContent.description}
              buttons={heroContent.ctaButtons}
              location={location}
              //crumbLabel={heroContent.title}
            />
            <Box backgroundColor="rgba(255, 255, 255, 0.8)"><GatsbyImage image={getImage(document.image.localFile)} /></Box>
            {/* <Box padding="4" backgroundColor="rgba(255, 255, 255, 0.8)">
  
            <Heading textAlign="center" color="sunset.500">
              Resources For Smart Growth
            </Heading>
          </Box> */}
          {/* <Box>
            <RichText render={heroContent.description} serializeHyperlink={CustomLink} />
          </Box> */}
          {/* <Box padding="4" backgroundColor="rgba(255, 255, 255, 0.8)">
            <ProjectCardRack />
          </Box> */}
          
            <BlogPostsBlock />
          
          <Box>
            <SliceZone sliceZone={document.body}
             />
          </Box>
   
            {/* <FacebookFeed /> */}

            <Footer />
          </MainContentBox>
          </Box>
        </Container>

    </HomeBackground>
    

  </>
  )
}
export const query = graphql`
{
  allPrismicHomepage {
    edges {
      node {
        data {
          body {
            ... on PrismicHomepageDataBodyCallToAction {
              id
              items {
                button_color
                button_label
                button_link {
                  uid
                  url
                }
              }
              primary {
                background_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
                    }
                  }
                }
                title {
                  raw
                  text
                }
                paragraph {
                  raw
                  text
                }
              }
              slice_type
            }
            ... on PrismicHomepageDataBodyTeamPromoBlock {
              id
              slice_type
              primary {
                link_to_team {
                  uid
                }
                paragraph {
                  raw
                }
                team_title {
                  text
                }
                team_photo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
                    }
                  }
                  alt
                }
              }
            }
            ... on PrismicHomepageDataBodyNewsletterSignUp {
              id
              slice_type
              primary {
                button_color
                button_text {
                  text
                }
                paragraph {
                  text
                }
                title {
                  text
                }
              }
            }
            ... on PrismicHomepageDataBodyOurWorkCards {
              id
              slice_type
              items {
                project_title {
                  text
                }
                project_link {
                  url
                  uid
                }
                project_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        aspectRatio: 1
                        placeholder: BLURRED
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                brief_project_description {
                  text
                }
                featured_project
              }
            }
          }
          page_title {
            text
          }
          page_description {
            text
          }
          cta_buttons {
            button_color
            cta_button_label {
              text
            }
            cta_button_link {
              url
              uid
            }
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
}
`

export default IndexPage

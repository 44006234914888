import React from "react"
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby"
import { Box, Heading, Text, Link, Flex } from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { ChevronRightIcon } from "@chakra-ui/icons"

const BlogPost = () => {
  const data = useStaticQuery(graphql`
  query PostQuery {
    allPrismicBlogPost(sort: {fields: data___publish_date, order: DESC}, limit: 6) {
      edges {
        node {
          uid
          tags
          data {
            featured_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 60
                    aspectRatio: 1.5
                    transformOptions: {cropFocus: CENTER}
                    
                  )
                }
              }
            }
            post_category
            post_excerpt {
              text
            }
            post_title {
              text
            }
            publish_date(formatString: "MMMM, D YYYY")
          }
        }
      }
    }
  }
  `)

  const document = data.allPrismicBlogPost.edges

  return (
      <Flex flexWrap="wrap">
    
    {document.map((post) => {
      //const allTags = post.node.data.tags
        return ( 
            <Box w={{sm: '100%', md: "33.33%"}} px={{sm: 0, md: '1'}} mb="8">
                <Flex flexDirection="column"
                    justifyContent="space-betweeen"
                    height="100%"
                    flexGrow="1">
                <Box mb="1rem">
                  <Link as={GatsbyLink} to={`/news/${post.node.uid}`} variant="blog-link">
                  <GatsbyImage image={getImage(post.node.data.featured_image.localFile)} />
                  </Link>
                  </Box>
      <Box><Heading fontSize="1.25rem" color="brand.400">{post.node.data.post_title.text}</Heading></Box>
      <Box><Text>{post.node.data.publish_date}</Text></Box>
      <Box><Text>{post.node.data.post_excerpt.text}</Text></Box>
     
                          {/* <Flex>
                            {postTags.map(postTag => {
                              return (
                                <TagButton
                                  linkTo={`/news/${postTag}`}
                                  linkText={postTag}
                                />
                              )
                            })}
                          </Flex> */}
                    <Box />
     
      
      
            <Box mt="auto"><Link as={GatsbyLink} to={`/news/${post.node.uid}`} variant="blog-link">Read More <ChevronRightIcon /></Link></Box>
                </Flex>
      
    </Box>
        )
    })} 
    </Flex>
  )
}

export default BlogPost

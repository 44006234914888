import React from 'react'
import { Box, Flex, Heading, Text, Button, Link } from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'

import { FaChevronRight } from "react-icons/fa"
import { GatsbyImage } from 'gatsby-plugin-image'

const ProjectCard = (props) => {
    return (
        <Box
            width={{base: '100%', lg: '33.33%'}}
            px={{base: 0, lg: '1'}}
        >
            <Flex
                flexDirection="column"
                flexGrow="1"
                justifyContent="space-between"
                color="white"
                textAlign="center"
                h="100%"
                bg="brand.400"
            >
                <Box>
                    <GatsbyImage image={props.projectImage} alt={props.alt} />
                </Box>
                <Box p="4">
                    <Heading as="h4" 
                    fontSize="1.5rem"
                    >
                        {props.projectTitle}
                    </Heading>
                </Box>
                <Box px="4">
                <Text>{props.projectText}</Text>
                </Box>
                <Box mt="auto" pb="4">
                    <Link to={props.projectLink} as={GatsbyLink}>
                    <Button variant="ghost" fontWeight="700" _hover={{ bg: 'none', color: 'sunset.400', textDecoration: 'none !important'}}>
                        Learn More <span style={{marginLeft: '5px'}}><FaChevronRight /></span>
                    </Button>
                    </Link>
                    </Box>
            </Flex>
        </Box>
    )
}

export default ProjectCard
import React from 'react'
import { Box,
        Flex,
       } from '@chakra-ui/react'
import ProjectCard from './ProjectCard'
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

const ProjectCardRack = () => {
    const data = useStaticQuery(graphql`
    {
      valleyMap: file(relativePath: { eq: "valley-map.jpg" }) {
        childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
        }
      }
      tetonView: file(relativePath: { eq: "Teton-View-Region-Map.png" }) {
        childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
        }
      }
      driggsImpact: file(relativePath: { eq: "Driggs-Impact-Area-Zoning-Map.jpg" }) {
        childImageSharp {
            gatsbyImageData(
                layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
        }
      }
    }
`)
const valleyMapImage = getImage(data.valleyMap)
const tetonViewMap = getImage(data.tetonView)
const driggsImpactMap = getImage(data.driggsImpact)
    return (
        <Box>
            <Flex
                flexWrap="wrap"
               className="project-card-rack"
                    >
            <ProjectCard
              projectImage={valleyMapImage}
              alt="Valley Image Map"
              projectTitle="Teton Valley Development Map"
              projectText="We actively track development in the 5 jurisdictions of Teton Valley (Driggs, Victor, Tetonia, Teton County Idaho, and Teton County Wyoming)."
              projectLink="/teton-valley-development-map"
            />
            <ProjectCard
              projectImage={tetonViewMap}
              alt="Teton View Map"
              projectTitle="Teton View Regional Plan"
              projectText="The Teton View Regional Plan (TVRP) is a strategy and plan for a four-county, seven-city region that straddles two states and is adjacent to Yellowstone and Grand Teton National Parks."
              projectLink="/teton-view-regional-plan"
            />
            <ProjectCard 
              projectImage={driggsImpactMap}
              alt="Driggs Impact Map"
              projectTitle="Studies &amp; Resources"
              projectText="Here are the various studies and reports that VARD has done in-house or in cooperation with project partners."
              projectLink="/studies-and-resources"
            />
            </Flex>
        </Box>
    )
}

export default ProjectCardRack